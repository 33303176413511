import SimpleContent from "../SimpleContent/SimpleContent";
import "./UnsubscribeConfirmed.scss";
import locale from "../../assets/locale.json";
import HighlightedParagraph from "../HighlightedParagraph/HighlightedParagraph";
import unsubscribeCompleted from "../../assets/images/unsubscribe-completed.webp";
import { ContentProps, EnumContentModal } from "../../models/content";
import { EnumIcon } from "../../models/enumIcon";
import { environment } from "../../environment/environment";
import { useCallback } from "react";
import useModal from "../../utils/useModalUtils";
import ModalError from "../ModalError/ModalError";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../utils/AuthContext";

export const UnsubscribeConfirmed: React.FC<ContentProps> = ({
  changeContent = () => { },
  onClose = () => { },
}) => {
  const { ModalType, closeModal, isModalOpen, openModal } = useModal();
  const { button, description, title } = locale.modal_error.errorUnregister;
  const navigate = useNavigate();
  const { setIsAuth } = useAuthContext();
  const fetchUnsubscribe = useCallback(async () => {
    try {
      const response = await fetch(`${environment.apiDomain}/unregister`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      if (response.ok) {
        localStorage.removeItem("user");
        window.dispatchEvent(new Event("userRegistered"));
        changeContent(EnumContentModal.UNSUBSCRIBE_COMPLETED);
      } else if (response.status === 401) {
        onClose();
        setIsAuth(false);
        localStorage.removeItem("user");
        window.dispatchEvent(new Event("userRegistered"));
        navigate("/courtesy-landing");
      } else if (response.status === 500) {
        openModal(ModalType.MODAL_ERROR);
      }
    } catch (error) {
      console.error(error);
      openModal(ModalType.MODAL_ERROR);
    }
  }, [ModalType, openModal, changeContent, onClose, navigate, setIsAuth]);

  return (
    <>
      <div className="pw-unsubscribe-confirmed-container">
        <SimpleContent
          image={{
            alt: locale.unsubscribe.unsubscribe_confirmed.alt_image,
            src: unsubscribeCompleted,
          }}
          title={locale.unsubscribe.unsubscribe_confirmed.title}
          subtitle={locale.unsubscribe.unsubscribe_confirmed.subtitle}
          description={() => (
            <HighlightedParagraph
              className="pw-highlight-paragraph"
              text={locale.unsubscribe.unsubscribe_confirmed.description}
            />
          )}
          confirmationButton={{
            theme: "blue",
            label: locale.unsubscribe.unsubscribe_confirmed.confirm_button,
            onClick: () => {
              fetchUnsubscribe();
            },
          }}
          cancellationButton={{
            theme: "blue underline",
            label: locale.unsubscribe.unsubscribe_confirmed.cancel_button,
            icon: EnumIcon.arrowLeft,
            onClick: () => onClose(),
          }}
        />
      </div>
      {/* Modal Error */}
      <ModalError
        title={title}
        description={description}
        button={{
          label: button.label,
          theme: "blue",
          onClick: () => {
            closeModal(ModalType.CLOSE);
            onClose()
          },
        }}
        isOpen={isModalOpen(ModalType.MODAL_ERROR)}
      />
    </>
  );
};

export default UnsubscribeConfirmed;
