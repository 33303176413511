import "./CourtesySection.scss";
import locale from "../../assets/locale.json";
import Button from "../Button/Button";
import { environment } from "../../environment/environment";
import { useParseText } from "../../utils/useParseText";

const CourtesySection = () => {
  const { parseText } = useParseText();
  return (
    <div className="pw-courtesy-section pw-container">
      <h1 
        className="pw-courtesy-title title-five-bold"
        dangerouslySetInnerHTML={parseText(locale.courtesy_section.title)} 
      />
      <p className="pw-courtesy-description">
        {locale.courtesy_section.description}
      </p>
      <Button
        className="pw-courtesy-button"
        theme={"blue"}
        label={locale.courtesy_section.label_button}
        url={environment.apiLoginMobile}
      />
    </div>
  );
};

export default CourtesySection;