import "./SimpleContent.scss";
import Button from "../Button/Button";
import { ISimpleContentProps } from "../../models/simpleContent";
import { useParseText } from "../../utils/useParseText";
import serialize from "../RichText/RichText";
import renderCopyright from "../../utils/renderCopyright";

const SimpleContent: React.FC<ISimpleContentProps> = ({
  image: { alt, src, copyright },
  title,
  description,
  subtitle,
  confirmationButton,
  cancellationButton,
  isLogoutVariant = false,
  note,
  headerLogo,
}) => {
  const { parseText } = useParseText();
  const titleContent = parseText(title);

  const subtitleContent = () => {
    const commonClass = "pw-simple-content-subtitle subtitle-simple-content";
    if (typeof subtitle === "string") {
      return <h3 className={commonClass}>{subtitle}</h3>
    } else if (typeof subtitle === "object" && "__html" in subtitle) {
      return <h3 className={commonClass} dangerouslySetInnerHTML={subtitle} />
    } else {
      return <h3 className={commonClass}>{serialize(subtitle!)}</h3>
    }
  }

  return (
    <div className="pw-simple-content">
      {headerLogo &&
        <h3 className="pw-simple-content-header-logo title-four-bold" dangerouslySetInnerHTML={parseText(headerLogo)} />
      }
      <div
        className={`pw-simple-content-body ${isLogoutVariant ? "logout-variant" : ""}`}
      >
        <div className="pw-simple-container-image">
          <img
            src={src}
            alt={alt}
            className="pw-simple-content-image"
            loading="lazy"
          />
          {renderCopyright(copyright!)}
        </div>
        {note && <span className="pw-simple-content-note">{serialize(note)}</span>}
        <div className="pw-simple-content-info">
          <h2
            className="paragraph-semi-bold pw-simple-content-title"
            dangerouslySetInnerHTML={titleContent}
          />
          {subtitle && subtitleContent()}
          {description && description()}
        </div>
      </div>
      {(confirmationButton || cancellationButton) && (
        <div className="pw-simple-content-action">
          {confirmationButton && (
            <Button
              {...confirmationButton}
              className={`button-label-md pw-simple-content-action-button ${confirmationButton.className ? confirmationButton.className : ""}`}
            />
          )}
          {cancellationButton && (
            <Button
              {...cancellationButton}
              className={`button-label-md pw-simple-content-action-button ${cancellationButton.className ? cancellationButton.className : ""}`}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SimpleContent;
