import { ReactNode } from "react";
import "./InfoBox.scss";

interface InfoBoxProps {
  image: string;
  alt: string;
  text: string | ReactNode;
  className?: string;
}

function InfoBox({ text, image, alt, className }: InfoBoxProps) {
  return (
    <div className={`${className} pw-info-box`}>
      <div className="pw-info-box-container-image">
        <img className="pw-info-box-image" src={image} alt={alt} />
      </div>
      {text}
    </div>
  );
}

export default InfoBox;
