export interface ContentProps {
  changeContent?: (content: EnumContentModal) => void;
  onClose?: () => void;
  isWinVariant?: boolean;
  onAnimationComplete?: () => void;
  openGame?: () => void;
}

export enum EnumContentModal {
  PRE_REGISTRATION = "preRegistration",
  THANK_YOU_PAGE = "thankYouPage",
  CONFIRMATION_MESSAGE = "confirmationMessage",
  UNSUBSCRIBE_CONFIRMED = "unsubscribeConfirmed",
  UNSUBSCRIBE_COMPLETED = "unsubscribeCompleted",
  CANNOT_PARTECIPATE = "cannotParticipate",
  PLAY_GAME = "playGame",
  YOU_LOSE = "youLose",
  TRY_AGAIN = "tryAgain",
  YOU_WIN = "youWin",
  FINISHED_PLAYS = "finishedPlays",
  DAYLY_LIMIT_REACHED = "dailyLimitReached",
  GAME_WIN_EXPERIENCE_AWARD = "gameWinExperienceAward",
  GAME_WIN_DIGITAL_AWARD = "gameWinDigitalAward",
}