import React, { Fragment } from "react";
import "./RichText.scss";
import {
  RichTextNode,
  IRichTextSection,
} from "../../models/richText";
import { useParseText } from "../../utils/useParseText";
import { Link } from "react-router-dom";
import { Text } from "slate";

const RenderNode = (node: RichTextNode, key: number): React.ReactNode => {
  const { parseText } = useParseText();
  if (!node) return null;
  const { url, children, type, newTab } = node;

  if (Text.isText(node)) {
    const isEmptyText = !node.text.trim();

    let text = (
      <span className="pw-rich-span" dangerouslySetInnerHTML={parseText(node.text)} />
    );

    if (isEmptyText) {
      text = <br />;
    } else {
      if (node.bold) {
        text = <strong key={key}>{text}</strong>;
      }

      if (node.italic) {
        text = <em key={key}>{text}</em>;
      }

      if (node.underline) {
        text = (
          <span style={{ textDecoration: "underline" }} key={key}>
            {text}
          </span>
        );
      }

      if (node.strikethrough) {
        text = (
          <span style={{ textDecoration: "line-through" }} key={key}>
            {text}
          </span>
        );
      }
    }

    return <Fragment key={key}>{text}</Fragment>;
  }

  const renderChildren = children.map((childNode, index) =>
    RenderNode(childNode, index)
  );

  switch (type) {
    case "link":
      return (
        <Link
          to={url}
          key={key}
          className="pw-rich-link pw-txt-none"
          target={newTab ? "_blank" : "_self"}
        >
          {renderChildren}
        </Link>
      );
    case "blockquote":
      return (
        <blockquote key={key} className="pw-blockquote" >
          {renderChildren}
        </blockquote>
      );
    default:
      return renderChildren
  }
};

const serialize = (children: IRichTextSection): React.ReactElement => {
  return <Fragment>{children?.map((node, i) => RenderNode(node, i))}</Fragment>;
};

export default serialize;
