import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation, Thumbs } from "swiper/modules";
import "./Thumbnail.scss";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import Button from "../Button/Button";
import { EnumIcon } from "../../models/enumIcon";
import locale from "../../assets/locale.json";
import { blockScroll } from "../../utils/blockScroll";
import { ImagesPrizes } from "../../models/prizes";
import { environment } from "../../environment/environment";
import renderCopyright from "../../utils/renderCopyright";

SwiperCore.use([Navigation, Thumbs]);

export interface IThumbnailProps {
  images: {
    experienceId?: number,
    images: ImagesPrizes[]
  }[];
}

export const Thumbnail: React.FC<IThumbnailProps> = ({ images }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  useEffect(() => {
    blockScroll(activeIndex !== null);
  }, [activeIndex]);

  const renderImage = (
    image: string,
    alt: string,
    indexSlider: number,
    classname: string,
    copyright?: string
  ) => {
    return (
      <div
        key={indexSlider}
        className={`${classname}`}
        onClick={() => setActiveIndex(indexSlider)}
      >
        <img
          src={environment.apiImage + "/" + image}
          alt={alt}
          className="pw-single-big-image"
          loading="lazy"
        />
        {renderCopyright(copyright!)}
      </div>
    );
  };

  return (
    <div className="pw-thumbnail-container">
      <div className="pw-thumbnail-content">
        {/* Big image */}
        {images && renderImage(
          images[0].images[0].filename,
          images[0].images[0].alt,
          0,
          "pw-thumbnail-big-image",
          images[0].images[0].copyright!
        )}

        {/* Small images */}
        <div className="pw-thumbnail-small-images-container">
          {images &&
            images[0]
              .images.slice(1, 4)
              .map((image, index) =>
                renderImage(
                  image.filename,
                  image.alt,
                  index + 1,
                  "pw-thumbnail-small-image",
                  image.copyright!
                ))
          }
        </div>
      </div>

      {/* Overlay Slider */}
      {activeIndex !== null && (
        <div
          className="pw-thumbnail-slider-container"
          onClick={() => {
            setActiveIndex(null);
          }}
        >
          <div
            className="pw-thumbnail-overlay pw-container"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="pw-close-button-container">
              <Button
                theme={"blue"}
                icon={EnumIcon.close}
                label={locale.experience_awards_section.button_label_thumbnail}
                className="pw-thumbnail-slider-button-close"
                onClick={() => setActiveIndex(null)}
              />
            </div>
            <Swiper
              spaceBetween={10}
              navigation
              initialSlide={activeIndex}
              onSlideChange={(swiper: SwiperCore) => {
                setActiveIndex(swiper.activeIndex);
              }}
              className="pw-thumbnail-slider"
            >
              {images.map(({ images }, parentIndex) =>
                images.map(({ filename, alt, copyright }, childIndex) => (
                  <SwiperSlide key={`${parentIndex}-${childIndex}`}>
                    <img
                      src={`${environment.apiImage}/${filename}`}
                      alt={alt}
                      className="pw-thumbnail-slider-slide"
                      loading="lazy"
                    />
                    {renderCopyright(copyright!)}
                  </SwiperSlide>
                ))
              )}
            </Swiper>
          </div>
        </div>
      )}
    </div>
  );
};
