type WebpackRequireContext = {
    keys: () => string[];
    (id: string): string;
    resolve(id: string): string;
    id: string;
};

export interface ImageMap {
    [key: string]: string;
}

export function importAll(r: WebpackRequireContext): ImageMap {
    return r.keys().reduce((images: ImageMap, key: string) => {
        images[key] = r(key);
        return images;
    }, {});
}

export const getImage = (value?: string) => value?.split("/")[1].split(".")[0];