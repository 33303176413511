import "./PreRegistration.scss";
import locale from "../../assets/locale.json";
import { useComponentVisible } from "../../utils/useDeviceSize";
import { useEffect, useRef, useState } from "react";
import { useParseText } from "../../utils/useParseText";
import { ContentProps, EnumContentModal } from "../../models/content";
import headerModalMobile from "../../assets/images/hero-desktop-modal.png";
import headerModalDesktop from "../../assets/images/hero-image.webp";
import Checkbox from "../Checkbox/Checkbox";
import Button from "../Button/Button";
import { EnumIcon } from "../../models/enumIcon";
import ErrorMessageSubscribe from "../ErrorMessageSubscribe/ErrorMessageSubscribe";
import { environment } from "../../environment/environment";
import { useNavigate } from "react-router-dom";
import useModal from "../../utils/useModalUtils";
import ModalError from "../ModalError/ModalError";
import { useAuthContext } from "../../utils/AuthContext";

export const PreRegistration: React.FC<ContentProps> = ({
  changeContent = () => {},
}) => {
  const { isTablet } = useComponentVisible();
  const { parseText } = useParseText();
  const inputRef = useRef<HTMLFormElement>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [checkPrivacyInformationChecked, setCheckPrivacyInformationChecked] = useState(false);
  const [checkRegulationChecked, setCheckRegulationChecked] = useState(false);
  const navigate = useNavigate();
  const { openModal, closeModal, ModalType, isModalOpen } = useModal();
  const { setIsAuth } = useAuthContext();

  const handleCheckboxChange = () => {
    if (checkRegulationChecked && checkPrivacyInformationChecked) {
      setErrorMessage(null);
      if (inputRef.current) {
        inputRef.current.classList.remove("form-error");
      }
    }
  };

  useEffect(() => {
    handleCheckboxChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkRegulationChecked, checkPrivacyInformationChecked]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let hasError = false;

    var data = new FormData(event.target as HTMLFormElement);
    let formObject = Object.fromEntries(data.entries());

    if (!formObject.checkRegulation || !formObject.checkPrivacyInformation) {
      hasError = true;
      if (inputRef.current) {
        inputRef.current.classList.add("form-error");
      }
      setErrorMessage('error');
    }

    if (hasError) {
      return;
    }

    try {
      const response = await fetch(`${environment.apiDomain}/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (response.ok) {
        const userData = await response.json();
        localStorage.setItem("user", JSON.stringify(userData));
        changeContent(EnumContentModal.THANK_YOU_PAGE);
      } else if (response.status === 401) {
        setIsAuth(false);
        navigate("/courtesy-landing");
        closeModal(ModalType.CLOSE);
      } else if (response.status === 500) {
        openModal(ModalType.MODAL_ERROR);
      }
    } catch (error) {
      console.error(error);
      openModal(ModalType.MODAL_ERROR);
    }
  };

  return (
    <>
      <div className="pw-pre-registration-container">
        <img
          className="pw-pre-registration-image"
          src={!isTablet ? headerModalMobile : headerModalDesktop}
          alt={locale.pre_registration.alt_image_header}
        />
        <h2 className="pw-title-modal-bold pw-pre-registration-title">
          {locale.pre_registration.title}
        </h2>

        <form
          noValidate
          onSubmit={handleSubmit}
          className="pw-pre-registration-form"
          ref={inputRef}
        >
          <div className="pw-pre-registration-container-description paragraph-small">
            <p
              className="pw-pre-registration-description"
              dangerouslySetInnerHTML={parseText(
                locale.pre_registration.description
              )}
            />

            <Checkbox
              className="pw-pre-registration-checkbox"
              label={parseText(locale.pre_registration.checkbox_regulation)}
              id="check1"
              name="checkRegulation"
              onChange={(e) => setCheckRegulationChecked(e.target.checked)}
            />

            <Checkbox
              className="pw-pre-registration-checkbox"
              label={parseText(
                locale.pre_registration.checkbox_privacy_information
              )}
              id="check2"
              name="checkPrivacyInformation"
              onChange={(e) => setCheckPrivacyInformationChecked(e.target.checked)}
            />

            {errorMessage && (
              <div className="pw-pre-registration-error">
                <ErrorMessageSubscribe />
              </div>
            )}
          </div>

          <div className="pw-pre-registration-container-button">
            <Button
              className={
                "pw-pre-registration-button pw-pre-registration-buttom-submit order-sm-2"
              }
              theme={"blue"}
              label={locale.pre_registration.button_subscribe}
              type="submit"
            />

            <Button
              className={"pw-pre-registration-button"}
              theme={"blue underline"}
              label={locale.pre_registration.button_do_it_later}
              icon={EnumIcon.arrowLeft}
              onClick={() => changeContent(EnumContentModal.CONFIRMATION_MESSAGE)}
            />
          </div>
        </form>
      </div>
      <ModalError
        title={locale.modal_error.errorRegistration.title}
        description={locale.modal_error.errorRegistration.description}
        button={{
          label: locale.modal_error.errorRegistration.button.label,
          theme: "blue",
          onClick: () => closeModal(ModalType.CLOSE)
        }}
        isOpen={isModalOpen(ModalType.MODAL_ERROR)}
      />
    </>
  );
};

export default PreRegistration;