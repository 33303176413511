import React from "react";
import { Link } from "react-router-dom";
import "./Breadcrumb.scss";
import { EnumIcon } from "../../models/enumIcon";

interface Breadcrumbs {
  path: string;
  label: string;
}

interface BreadcrumbsProps {
  crumbs: Breadcrumbs;
}

const Breadcrumb: React.FC<BreadcrumbsProps> = ({ crumbs }) => {
  return (
    <nav
      className="pw-breadcrumb paragraph-semi-bold-xs"
      aria-label="breadcrumb"
    >
      <div className="pw-breadcrumb-container">
        <Link className="pw-breadcrumb-link" to={crumbs.path}>
          <span className="pw-breadcrumb-label">
            <i className={`${EnumIcon.goBack} pw-breadcrumb-icon-arrow`}></i>
          </span>
          {crumbs.label}
        </Link>
      </div>
    </nav>
  );
};

export default Breadcrumb;