import React from "react";
import "./ModalGameWinDigitalAward.scss";
import SimpleContent from "../SimpleContent/SimpleContent";
import locale from "../../assets/locale.json";
import HighlightedParagraph from "../HighlightedParagraph/HighlightedParagraph";
import { useParseText } from "../../utils/useParseText";
import { PrizeAwardProps } from "../../models/prizeAward";
import { environment } from "../../environment/environment";

interface ModalGameWinDigitalAwardProps {
  onClose: () => void;
  prize: PrizeAwardProps;
}

const ModalGameWinDigitalAward: React.FC<ModalGameWinDigitalAwardProps> = ({
  onClose,
  prize
}) => {
  const { parseText } = useParseText();
  const subtitleWithAmount = `${locale.modal_game_win_digital_award.subtitle} ${prize.prize.amount}€`;
  const textSubtitle = parseText(subtitleWithAmount);

  return (
    <div className="pw-game-win-digital-award-container">
      <SimpleContent
        headerLogo={locale.modal_game_win_digital_award.logo}
        image={{
          alt: prize.prize.image.alt,
          src: environment.apiImage + "/" + prize.prize.image.filename,
        }}
        note={prize.prize.note}
        title={locale.modal_game_win_digital_award.title}
        subtitle={textSubtitle}
        description={() => (
          <HighlightedParagraph
            text={locale.modal_game_win_digital_award.description}
          />
        )}
        confirmationButton={{
          theme: "blue",
          label: locale.modal_game_win_digital_award.confirmation_label,
          onClick: () => onClose(),
        }}
      />
    </div>
  );
};

export default ModalGameWinDigitalAward;