import "./index.scss";
import NavBar from "./components/NavBar/NavBar";
import AppRouter from "./routes/routes";
import Footer from "./components/Footer/Footer";
import { useEffect } from "react";
import ModalError from "./components/ModalError/ModalError";
import useModal from "./utils/useModalUtils";
import { useAuthContext } from "./utils/AuthContext";
import locale from "./assets/locale.json";
import Loader from "./components/Loader/Loader";

function App() {
  const { ModalType, isModalOpen, openModal } = useModal();
  const { responseError, isLoading } = useAuthContext();
  const { title, description, button: { label, url } } = locale.modal_error.error500;

  useEffect(() => {
    if (responseError === 500) {
      openModal(ModalType.MODAL_ERROR);
    }
  }, [ModalType, openModal, responseError]);

  if (isLoading) return <Loader />

  return (
    <>
      <NavBar />
      <AppRouter />
      <Footer />
      <ModalError
        title={title}
        description={description}
        button={{
          label: label,
          theme: "blue",
          url: url
        }}
        isOpen={isModalOpen(ModalType.MODAL_ERROR)}
      />
    </>
  );
}

export default App;
