import React from "react";
import "./DigitalAward.scss";
import locale from "../../assets/locale.json";
import { useComponentVisible } from "../../utils/useDeviceSize";
import ButtonGoBack from "../ButtonGoBack/ButtonGoBack";
import { IDigitalVoucher } from "../../models/digitalVoucher";
import serialize from "../RichText/RichText";
import { environment } from "../../environment/environment";

const DigitalAward: React.FC<IDigitalVoucher> = ({
  title,
  description,
  amounts,
  image,
  note
}) => {
  const { isMobile } = useComponentVisible();
  return (
    <div className="pw-digital-award-container">
      <div className="pw-container">
        <ButtonGoBack label={locale.digital_award.label_button} />
        <div className="pw-digital-award-content">
          <div className="row">
            <div className="pw-container-image-digital-award col-md-6">
              {isMobile && (
                <h1 className="pw-title-award pw-digital-award-title">
                  {title}
                </h1>
              )}
              <div className="pw-content-image-digital-award">
                <img
                  src={environment.apiImage + "/" + image.filename}
                  alt={image.alt}
                  className="pw-digital-award-image"
                  loading="lazy"
                />
                {note &&
                  <p className="pw-digital-award-restrictions">
                    {serialize(note)}
                  </p>
                }
              </div>
            </div>
            <div className="col-md-5">
              {!isMobile && (
                <h1 className="pw-title-award pw-digital-award-title">
                  {title}
                </h1>
              )}
              <p className="paragraph">{serialize(description)}</p>
              <h2 className="pw-digital-award-subtitle">
                {locale.digital_award.subtitle}
              </h2>
              {amounts.map((amount, i) => (
                <span className="pw-digital-award-number" key={i}>€{amount}</span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalAward;
