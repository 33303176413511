import ExperienceAward from "../components/ExperienceAward/ExperienceAward";
import { useParams } from "react-router-dom";
import "../assets/scss/page/ExperienceAwardLanding.scss";
import useSinglePrizeType from "../utils/useSinglePrizeType";
import { IExperienceAwardProps } from "../models/experienceAward";
import { EnumPrizeType } from "../models/prizes";
import Loader from "../components/Loader/Loader";

const ExperienceAwardLanding = () => {
  const { id } = useParams<{ id: string }>();
  const { singlePrize, isLoading } = useSinglePrizeType<IExperienceAwardProps>(EnumPrizeType.EXPERIENCE, Number(id));

  if (isLoading) {
    return <Loader />;
  }

  if (!singlePrize) {
    return <p>Experience award not found</p>;
  }

  return (
    <div className="pw-page-experience-award">
      <div className="pw-container">
        <ExperienceAward data={singlePrize} />
      </div>
    </div>
  )
};

export default ExperienceAwardLanding;