import "./ThankYouPage.scss";
import locale from "../../assets/locale.json";
import { EnumIcon } from "../../models/enumIcon";
import Button from "../Button/Button";
import { useParseText } from "../../utils/useParseText";
import { ContentProps } from "../../models/content";

const ThankYouPage: React.FC<ContentProps> = ({
  onClose = () => { },
  openGame = () => { },
}) => {
  const { parseText } = useParseText();
  return (
    <div className="pw-thank-you-container">
      <div className="pw-thank-you-content">
        <h3 className="pw-thank-you-logo" dangerouslySetInnerHTML={parseText(locale.thank_you_page.logo)} />
        <p className="pw-thank-you-text paragraph-semi-bold-medium">{locale.thank_you_page.description}</p>
        <p className="pw-thank-you-title paragraph-semi-bold-xl">{locale.thank_you_page.subtitle}</p>
        <div className="pw-thank-you-container-button">
          <Button
            theme={"white"}
            label={locale.thank_you_page.play_button}
            className="pw-thank-you-button order-sm-2"
            onClick={openGame}
          />
          <Button
            icon={EnumIcon.arrowLeft}
            theme={"white underline"}
            label={locale.thank_you_page.reject_button}
            className="pw-thank-you-button"
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;