import "./CarouselExperienceRewards.scss";
import "swiper/css";
import { Navigation, Pagination } from "swiper/modules";
import { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper";
import { EnumIcon } from "../../models/enumIcon";
import SimpleCard from "../SimpleCard/SimpleCard";
import { useComponentVisible } from "../../utils/useDeviceSize";
import locale from "../../assets/locale.json";
import usePrizes from "../../utils/usePrizes";

const CarouselExperienceRewards = () => {
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const swiperRef = useRef<SwiperType>();
  const { isMobile } = useComponentVisible();
  const { prizes } = usePrizes();

  return (
    <div className="pw-carousel-experience-container">
      <div className="pw-carousel-experience-button-container">
        <h2 className="pw-carousel-experience-subtitle">{locale.discover_prize_section.carousel_experience_title}</h2>
        <div className="pw-carousel-experience-button">
          <button
            disabled={currentSlide === 0}
            className={`pw-carousel-experience-navigation`}
            onClick={() => swiperRef.current?.slidePrev()}
          >
            <i className={`${EnumIcon.goBack} pw-navigation-icon`} />
          </button>
          <button
            disabled={prizes && (
              isMobile
                ? currentSlide === prizes.experiences.length - 1
                : currentSlide === prizes.experiences.length - 2
            )}
            className={`pw-carousel-experience-navigation`}
            onClick={() => swiperRef.current?.slideNext()}
          >
            <i className={`${EnumIcon.goAhead} pw-navigation-icon`} />
          </button>
        </div>
      </div>
      <Swiper
        modules={[Navigation, Pagination]}
        onBeforeInit={(swiper: any) => {
          swiperRef.current = swiper;
        }}
        slidesPerView="auto"
        breakpoints={{
          769: {
            slidesPerView: 2,
            slidesPerGroup: 2,
          },
        }}
        pagination={{
          el: ".custom-pagination",
          clickable: true,
        }}
        onSlideChange={(swiper: SwiperType) =>
          setCurrentSlide(swiper.activeIndex)
        }
        spaceBetween={24}
      >
        {prizes &&
          prizes.experiences.map((card, index) => (
            <SwiperSlide key={index}>
              <div className="pw-simple-card-carousel-reward">
                <SimpleCard {...card} path={`/experience/${card.id}`} />
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
      <div className="custom-pagination"></div>
    </div>
  );
};

export default CarouselExperienceRewards;
