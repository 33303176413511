import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { User } from "../models/user";
import locale from "../assets/locale.json";
import { environment } from "../environment/environment";

const apiAuth = `${environment.apiDomain}/profile`;

interface IAuthContext {
    user: User | null;
    isAuth: boolean;
    setIsAuth: React.Dispatch<React.SetStateAction<boolean>>;
    responseError: number | null;
    isLoading: boolean;
}

const AuthContext = createContext<IAuthContext | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const navigate = useNavigate();
    const [user, setUser] = useState<User | null>(null);
    const [isAuth, setIsAuth] = useState(false);
    const [responseError, setResponseError] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchUser = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(apiAuth, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Cache-Control": "no-cache",
                    },
                    credentials: "include",
                });
                const data: User = await response.json();

                if (!response.ok) {
                    switch (response.status) {
                        case 401:
                            setResponseError(response.status);
                            setIsAuth(false);
                            localStorage.removeItem("user");
                            window.dispatchEvent(new Event("userRegistered"));
                            navigate("/courtesy-landing");
                            break;
                        case 403:
                            setResponseError(response.status);
                            setIsAuth(false);
                            break;
                        case 404:
                            setResponseError(response.status);
                            setIsAuth(false);
                            break;
                        default:
                            setResponseError(response.status);
                            break;
                    }
                } else {
                    setUser(data);
                    setIsAuth(true);
                    localStorage.setItem("user", JSON.stringify(data));
                    window.dispatchEvent(new Event("userRegistered"));
                }
            } catch (error) {
                setResponseError(500);
                console.error("error", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchUser();
    }, [navigate]);

    return (
        <AuthContext.Provider value={{ user, isAuth, responseError, setIsAuth, isLoading }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuthContext = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error(locale.auth_context_error);
    }
    return context;
};
