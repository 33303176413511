import { IRichTextSection } from "./richText";

export interface ImagesPrizes {
    filename: string;
    alt: string;
    copyright?: string | null;
}

export interface ISimpleCardPrizes {
    id: string;
    title: string;
    shortDescription: IRichTextSection;
    note: IRichTextSection;
    image: ImagesPrizes;
    className?: string;
    path: string;
}

export interface IPrizes {
    digitalVouchers: ISimpleCardPrizes[];
    experiences: ISimpleCardPrizes[];
}

export enum EnumPrizeType {
    DIGITAL_VOUCHER = "digital-voucher",
    EXPERIENCE = "experience",
}