import { useEffect, useState } from "react";

export const useComponentVisible = () => {
  const [isMobile, setIsMobile] = useState<boolean>(true);
  const [isTablet, setIsTablet] = useState<boolean>(true);
  const updateSize = () => {
    setIsMobile(window.matchMedia("(max-width: 1023px)").matches);
    setIsTablet(window.matchMedia("(min-width: 768px)").matches);
  };

  useEffect(() => {
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  });

  return { isMobile, isTablet };
};
