import React from "react";
import "./InformationModule.scss";
import { PropertiesExperienceAward } from "../../models/experienceAward";
import serialize from "../RichText/RichText";
import { getPropertiesImg } from "../../assets/images/properties";
import locale from "../../assets/locale.json";

export interface InformationModuleProps {
  className?: string;
  box?: PropertiesExperienceAward[];
}

const InformationModule: React.FC<InformationModuleProps> = ({
  className,
  box,
}) => {
  return (
    <>
      {box &&
        box.map((item, boxIndex) => (
          <div
            key={boxIndex}
            className={`pw-information-module-container ${className || ""}`}
          >
            <div className="pw-information-module-box">
              <div className="pw-information-module-image-container">
                <img
                  className="pw-information-module-image"
                  src={getPropertiesImg(item.property.category)}
                  alt={locale.information_module_alt}
                />
              </div>
              <p className="pw-information-module-text paragraph-small">
                {serialize(item.property.description)}
              </p>
            </div>
          </div>
        ))}
    </>
  );
};

export default InformationModule;