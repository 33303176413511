import React from "react";
import "./SimpleCard.scss";
import { EnumIcon } from "../../models/enumIcon";
import { Link } from "react-router-dom";
import locale from "../../assets/locale.json";
import serialize from "../RichText/RichText";
import { ISimpleCardPrizes } from "../../models/prizes";
import { environment } from "../../environment/environment";
import renderCopyright from "../../utils/renderCopyright";

const SimpleCard: React.FC<ISimpleCardPrizes> = ({
  title,
  shortDescription,
  image = { alt: "", filename: "", copyright: "" },
  note,
  className,
  path,
}) => {
  const { alt, filename, copyright } = image;
  return (
    <>
      <div className={`pw-simple-card-container ${className || ""}`}>
        <div className="pw-container-image">
          <img
            className="pw-simple-card-image"
            src={environment.apiImage + "/" + filename}
            alt={alt}
            loading="lazy"
          />
          {renderCopyright(copyright!)}
        </div>
        <div className="pw-simple-card-content">
          {note && (
            <p className="pw-simple-card-note sub-paragraph">{serialize(note)}</p>
          )}
          <h2 className="paragraph-semi-bold-medium pw-simple-card-title">
            {title}
          </h2>
          <p className="pw-simple-card-description paragraph">
            {serialize(shortDescription)}
          </p>
          <Link
            className="pw-simple-card-cta"
            to={path}
          >
            <span>
              <i className={`${EnumIcon.goAhead} pw-simple-card-icon`} />
              {locale.simple_card.action_button_label}
            </span>
          </Link>
        </div>
      </div>
    </>
  );
};

export default SimpleCard;
