import React from "react";
import "./DiscoverPrizeSection.scss";
import CarouselExperienceRewards from "../CarouselExperienceRewards/CarouselExperienceRewards";
import locale from "../../assets/locale.json";
interface DiscoverPrizeSectionProps {
  className?: string;
}

const DiscoverPrizeSection: React.FC<DiscoverPrizeSectionProps> = ({
  className,
}) => {
  return (
    <section className={`pw-discover-prize-section ${className || ""}`}>
      <div className="pw-container">
        <h1 className="title-four-bold pw-carousel-experience-title">
          {locale.discover_prize_section.discover_prize_title}
        </h1>
        <p className="paragraph pw-carousel-experience-description">
          {locale.discover_prize_section.discover_prize_subtitle}
        </p>
        <CarouselExperienceRewards />
      </div>
    </section>
  );
};

export default DiscoverPrizeSection;
