import { useEffect, useRef } from "react";
import { AccordionData } from "../../models/accordion";
import "./AccordionItem.scss";

function AccordionItem({
  data,
  isOpen,
  btnOnClick,
}: {
  data: AccordionData;
  isOpen: boolean;
  btnOnClick: () => void;
}) {
  const contentRef = useRef<HTMLDivElement>(null);
  
  const itemRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (isOpen) {
      const itemEl = itemRef.current;
      if (itemEl) {
        itemEl.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    } 
  }, [isOpen]);

  return (
    <li ref={itemRef} className={`pw-accordion-item ${isOpen ? "active" : ""}`}>
      <h2 className="pw-accordion-item-title">
        <button
          className="pw-accordion-item-btn paragraph-semi-bold-xs"
          onClick={btnOnClick}
        >
          {data.title}
        </button>
      </h2>
      <div className={`pw-accordion-item-container ${!isOpen && "collapsed"}`}>
        <div
          ref={contentRef}
          className="pw-accordion-item-content paragraph-small"
        >
          {data.content}
        </div>
      </div>
    </li>
  );
}

export default AccordionItem;
