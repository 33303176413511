import "./CookieBanner.scss";

const CookieBanner = () => {
  return (
    <>
      <div className="pw-cookie-banner">
        <div id="consent_blackbar"></div>
      </div>
      <div className="footer-cookie paragraph-small" id="teconsent" />
    </>
  );
};

export default CookieBanner;
