import "./Faq.scss";
import locale from "../../assets/locale.json";
import Accordion from "../Accordion/Accordion";
import Button from "../Button/Button";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import useModalUtils from "../../utils/useModalUtils";
import ModalUnsubscribe from "../ModalUnsubscribe/ModalUnsubscribe";
import BoxAssistance from "../BoxAssistance/BoxAssistance";
import { EnumIcon } from "../../models/enumIcon";
import semiCirculo from "../../assets/images/semiCirculo.svg"
import { useComponentVisible } from "../../utils/useDeviceSize";
import { useAuthContext } from "../../utils/AuthContext";
import { useUser } from "../../utils/useUserExist";

interface FaqItemProps {
  title: string;
  content: string;
  label_button?: string;
}

export const Faq = () => {
  const { isAuth } = useAuthContext();
  const { faq } = locale;
  const user = useUser();
  const { closeModal, openModal, isModalOpen, ModalType } = useModalUtils();
  const { isMobile } = useComponentVisible();
  const crumbs = faq.crumbs;

  const faqSectionsItems = (items: FaqItemProps[], isButton = false) => 
    items.map(({ title, content, label_button }) => ({
      title,
      content: (
        <>
          <div dangerouslySetInnerHTML={{ __html: content }} />
          {isButton && label_button && (
            <Button
              className="pw-button-faq-section"
              theme="blue blue-border-transparent"
              disabled={user?.unregisteredAt !== null || !isAuth}
              label={label_button}
              onClick={() => openModal(ModalType.UNSUBSCRIBE)}
            />
          )}
        </>
      ),
    }));

  const faqSections = [
    { title: faq.title_requirements, items: faqSectionsItems(faq.general_questions) },
    { title: faq.title_registration, items: faqSectionsItems(faq.registration, true) },
    { title: faq.title_mechanics, items: faqSectionsItems(faq.mechanics) },
    { title: faq.title_cards_transactions, items: faqSectionsItems(faq.cards_transactions) },
    { title: faq.title_awards, items: faqSectionsItems(faq.awards) },
  ];

  return (
    <div className="pw-container-faq">
      {!isMobile && <img className="pw-image-faq" src={semiCirculo} alt="" />}

      <div className="pw-container">
      <Breadcrumb crumbs={crumbs} />
      <h1 className="pw-title-faq-section title-four-bold">
        {faq.title_faq_section}
      </h1>
      <h2 className="pw-subtitle-faq-section paragraph">
        {faq.subtitle_faq_section}
      </h2>
      {faqSections.map(({ title, items }) => (
        <div key={title}>
          <h3 className={`pw-title-faq paragraph-bold-small`}>{title}</h3>
          <Accordion items={items} />
        </div>
      ))}

      <div className="pw-help-center-container">
        <h2 className="pw-help-center-title paragraph-bold-small">{locale.faq.help_center.title}</h2>
        <div className="row">
          <div className="col-md-6 pw-box-assistance-help-center">
            <BoxAssistance
              title={locale.faq.help_center.tel.title}
              subtitle={locale.faq.help_center.tel.subtitle}
              description={locale.faq.help_center.tel.description}
              ctaLabel={locale.faq.help_center.tel.telephone}
              href={locale.faq.help_center.tel.href}
              icon={EnumIcon.phone}
            />
          </div>

          <div className="col-md-6">
            <BoxAssistance
              title={locale.faq.help_center.mail.title}
              subtitle={locale.faq.help_center.mail.subtitle}
              description={locale.faq.help_center.mail.description}
              ctaLabel={locale.faq.help_center.mail.mail}
              href={locale.faq.help_center.mail.href}
              icon={EnumIcon.mail}
            />
          </div>
        </div>
      </div>

    <ModalUnsubscribe isOpen={isModalOpen(ModalType.UNSUBSCRIBE)} onClose={() => closeModal(ModalType.CLOSE)} />
    </div>
  </div>
  )
}
