import { HeroSection } from "../components";
import CourtesySection from "../components/CourtesySection/CourtesySection";

export const CourtesyLanding = () => {
  return (
    <>
      <HeroSection />
      <CourtesySection />
    </>
  );
};