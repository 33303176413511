import { useEffect, useState } from "react";
import { IPrizes } from "../models/prizes";
import { environment } from "../environment/environment";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "./AuthContext";

const apiPrizes = `${environment.apiDomain}/prizes`;

const usePrizes = () => {
    const [prizes, setPrizes] = useState<IPrizes>()
    const navigate = useNavigate();
    const { setIsAuth } = useAuthContext();

    useEffect(() => {
        const fetchPrizes = async () => {
            try {
                const response = await fetch(apiPrizes, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    credentials: "include",
                })

                const data: IPrizes = await response.json();
                if (response.ok) {
                    setPrizes(data)
                } else if (response.status === 401) {
                    setIsAuth(false);
                    localStorage.removeItem("user");
                    window.dispatchEvent(new Event("userRegistered"));
                    navigate("/courtesy-landing");
                }
            } catch (error) {
                console.error("error", error)
            }
        }
        fetchPrizes()
    }, [navigate, setIsAuth])

    return { prizes }
}

export default usePrizes;