import Lottie from "lottie-react"
import loaderPostepay from "../../assets/lotties/LoaderPostepay.json"
import "./Loader.scss"

const Loader = () => {
    return (
        <div className="pw-loader-container">
            <Lottie
                animationData={loaderPostepay}
                className="pw-loader-dim"
                loop
            />
        </div>
    )
}

export default Loader