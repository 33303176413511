import React from "react";
import "./HighlightedParagraph.scss";
import { HighlightedParagraphProps } from "../../models/highlightedParagraph";
import { useParseText } from "../../utils/useParseText";

const HighlightedParagraph: React.FC<HighlightedParagraphProps> = ({
  className,
  text,
}) => {
  const { parseText } = useParseText();
  const textHighlightedParagraph = parseText(text);
  return (
    <p
      className={`${className ? className : ""} pw-highlighted paragraph-small`}
      dangerouslySetInnerHTML={textHighlightedParagraph}
    />
  );
};

export default HighlightedParagraph;