import React from "react"
import "./ModalError.scss";
import Button from "../Button/Button";
import { IButtonProps } from "../../models/button";
import { EnumIcon } from "../../models/enumIcon";

interface IModalErrorProps {
    title: string;
    description: string;
    button: IButtonProps;
    isOpen: boolean;
}

const ModalError: React.FC<IModalErrorProps> = ({ title, description, button, isOpen }) => {
    if (!isOpen) return null

    return (
        <div className="pw-modal-error">
            <div className="pw-error-container">
                <div className="pw-error-content">
                    <div className="pw-error-header">
                        <i className={`pw-error-icon ${EnumIcon.close}`}></i>
                        <h2 className="pw-error-title">{title}</h2>
                    </div>
                    <p className="pw-error-description paragraph">{description}</p>
                    <Button {...button} className={`pw-error-button button-label-md ${button.className ? button.className : ""}`} />
                </div>
            </div>
        </div>
    )
}

export default ModalError