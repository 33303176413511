import React from "react";
import "./ExperienceAward.scss";
import { Thumbnail } from "../../components/Thumbnail/Thumbnail";
import { useComponentVisible } from "../../utils/useDeviceSize";
import InformationModule from "../InformationModule/InformationModule";
import SimpleCard from "../SimpleCard/SimpleCard";
import { IExperienceAwardProps } from "../../models/experienceAward";
import locale from "../../assets/locale.json";
import ButtonGoBack from "../ButtonGoBack/ButtonGoBack";
import serialize from "../RichText/RichText";

interface ExperienceProps {
  data: IExperienceAwardProps;
}

const ExperienceAward: React.FC<ExperienceProps> = ({
  data: {
    title,
    images,
    description,
    properties,
    others,
  }
}) => {
  const { isMobile } = useComponentVisible();
  return (
    <div className="pw-experience-award-container">
      <ButtonGoBack label={locale.experience_awards_section.button_go_back} />
      <div className="row">
        <div className="col-md-6">
          {isMobile && (
            <h1 className="pw-title-experience-award">{title}</h1>
          )}
          <Thumbnail images={images} />
        </div>
        <div className="col-md-6">
          {!isMobile && (
            <h1 className="pw-title-experience-award">{title}</h1>
          )}
          <p className="pw-experience-award-description paragraph">
            {serialize(description)}
          </p>
          <InformationModule box={properties} />
        </div>
      </div>
      {!isMobile && (
        <div className="pw-container-detail-experience">
          <h1 className="pw-title-detail-experience">
            {locale.experience_awards_section.title_related}
          </h1>
          <div className="row">
            {others &&
              others.map((others, index) => (
                <div key={index} className="col-md-4">
                  <SimpleCard {...others} path={`/experience/${others.id}`} />
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ExperienceAward;