import "./Summary.scss";
import pwImageCreditCard from "../../assets/images/BubbleCard.webp";
import pwImageCoin from "../../assets/images/BubbleCoin.webp";
import pwImagePackage from "../../assets/images/BubblePackage.webp";
import locale from "../../assets/locale.json";
import { useParseText } from "../../utils/useParseText";
import InfoBox from "../InfoBox/InfoBox";

function Summary() {
  const { parseText } = useParseText();

  return (
    <section className="pw-container pw-container-summary">

      <div className="container-info">
        <h2
          className="title-two-regular pw-summary-title"
          dangerouslySetInnerHTML={parseText(locale.summary.title)}
        />
        <div className="info-box-wrapper">
          <div className="pw-info-wrapper-center row">
          <InfoBox
            className="col-4"
            image={pwImageCreditCard}
            alt={locale.summary.info_box.pw_creditCard.alt}
            text={
              <p
                className="paragraph-xs pw-info-box-text"
                dangerouslySetInnerHTML={parseText(locale.summary.info_box.pw_creditCard.text)}
              />
            }
          />
          <InfoBox
            className="col-4"
            image={pwImageCoin}
            alt={locale.summary.info_box.pw_coin.alt}
            text={
              <p
                className="paragraph-xs pw-info-box-text"
                dangerouslySetInnerHTML={parseText(locale.summary.info_box.pw_coin.text)}
              />
            }
          />
          <InfoBox
            className="col-4"
            image={pwImagePackage}
            alt={locale.summary.info_box.pw_package.alt}
            text={
              <p
                className="paragraph-xs pw-info-box-text"
                dangerouslySetInnerHTML={parseText(locale.summary.info_box.pw_package.text)}
              />
            }
          />
        </div>
        </div>
      </div>
    </section>
  );
}

export default Summary;