import "../assets/scss/page/PageNotFound.scss";
import { Button } from "../components";

import locale from "../assets/locale.json"
import { useParseText } from "../utils/useParseText";

export const PageNotFound = () => {
  const { parseText } = useParseText();

  return (
    <section className="pw-container-page-not-found">
      <div className="pw-page-not-found-hero-shadow" />
      <div className="pw-container">
        <h1 className="pw-page-not-found-title">{locale.page_not_found.title}</h1>
        <p className="pw-page-not-found-logo" dangerouslySetInnerHTML={parseText(locale.page_not_found.logo)} />
        <h2 className="pw-page-not-found-subtitle">{locale.page_not_found.subtitle}</h2>
        <p className="pw-page-not-found-description paragraph-small" dangerouslySetInnerHTML={parseText(locale.page_not_found.description)} />
        <Button
          className={"pw-page-not-found-button"}
          theme={"blue"}
          label={locale.page_not_found.button}
          url="/"
        />
      </div>
    </section>
  );
};
