import React from "react";
import "./ButtonGoBack.scss";
import { useNavigate } from "react-router-dom";
import { EnumIcon } from "../../models/enumIcon";

export interface ButtonGoBackProps {
  label: string;
}

const ButtonGoBack: React.FC<ButtonGoBackProps> = ({ label }) => {
  const navigate = useNavigate();
  return (
    <div>
      <button
        aria-label={label}
        className="pw-button-go-back"
        onClick={() => navigate(-1)}
      >
        <i className={`${EnumIcon.goBack} pw-icon-go-back`}></i>
        <span className="pw-button-go-back-label">{label}</span>
      </button>
    </div>
  );
};

export default ButtonGoBack;
