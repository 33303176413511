import { BoxAssistanceProps } from "../../models/boxAssistance";
import "./BoxAssistance.scss";

function BoxAssistance({
  title,
  subtitle,
  description,
  href,
  ctaLabel,
  className,
  icon,
}: BoxAssistanceProps) {
  return (
    <div className={`pw-box-assistance-container ${className || ""}`}>
        <div className="pw-box-assistance-content row">
            <div className="col-md-7">
                <h3 className="pw-box-assistance-title pw-title-box-assistance">{title}</h3>
                <h2 className="pw-box-assistance-subtitlen paragraph-semi-bold-medium ">{subtitle}</h2>
                <p className="pw-box-assistance-description paragraph">{description}</p>
            </div>
            <div className="pw-box-assistance-action col-md-5">
              
              <a className="pw-box-assistance-cta paragraph-semi-bold-medium" href={href}>
                <i className={`${icon} pw-box-assistance-icon`}></i> 
                <span className="pw-box-assistance-cta-label">{ctaLabel}</span>
              </a>
            </div>
        </div>
      </div>
  );
}

export default BoxAssistance;
